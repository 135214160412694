<template>
  <div>
    <b-form @submit.stop.prevent="formOnSubmit">
      <!-- Input Date -->
      <b-form-group id="input-group-date" label="Tanggal:" label-for="input-date">
        <b-form-datepicker
          v-model="form.date"
          :disabled="purpose == 'edit' && form.appointment_status_id == 4"
          id="input-date"
          placeholder="Tentukan Tanggal"
          locale="id"
        ></b-form-datepicker>
        <small class="text-danger">{{ error.date }}</small>
      </b-form-group>

      <!-- Input Modal Trigger -->
      <b-form-group
        id="input-group-trigger-modal-patient"
        label="Pasien:"
        label-for="input-trigger-modal-patient"
      >
        <b-form-input
          id="input-trigger-modal-patient"
          v-model="display.patient_name"
          :disabled="purpose == 'edit' && form.appointment_status_id == 4"
          placeholder="Pilih Pasien"
          readonly
          @click="$bvModal.show('modal-patient')"
        ></b-form-input>
        <small class="text-danger">{{ error.patient_id }}</small>
      </b-form-group>

      <!-- Input Doctor -->
      <b-form-group
        v-if="currentUser.user_type_id != 1"
        id="input-group-doctor"
        label="Dokter:"
        label-for="input-doctor"
      >
        <treeselect
          v-model="form.doctor_id"
          :multiple="false"
          :disabled="purpose == 'edit' && form.appointment_status_id == 4"
          @select="inputDoctorOnSelect"
          :options="doctors"
        />
        <small class="text-danger">{{ error.doctor_id }}</small>
      </b-form-group>

      <b-form-group>
        <template v-if="selectedDoctorSchedule.length == 0 && form.doctor_id != ''">
          <div>
            {{ selectedDoctorScheduleText }}
            <router-link :to="`/schedule/list?doctor_id=${form.doctor_id}`">Tambah Jadwal</router-link>
          </div>
        </template>
        <div v-if="selectedDoctorSchedule.length != 0">{{ selectedDoctorScheduleText }}</div>
      </b-form-group>

      <!-- Input Live Meeting Link -->
      <b-form-group id="input-group-live-meeting-link">
        <label for="input-live-meeting-link">
          Tautan Meeting:
          <em class="text-muted">opsional</em>
        </label>
        <b-form-input
          id="input-live-meeting-link"
          v-model="form.live_meeting_link"
          placeholder="Tautan Meeting"
        ></b-form-input>
      </b-form-group>

      <!-- Input Room -->
      <b-form-group id="input-group-room">
        <label for="input-room">
          Ruangan:
          <em class="text-muted">opsional</em>
        </label>
        <treeselect
          v-model="form.room_id"
          :multiple="false"
          :options="rooms"
          placeholder="Pilih Ruangan"
        />
        <small class="text-danger">{{ error.room_id }}</small>
      </b-form-group>

      <!-- Input Action Types -->
      <b-form-group id="input-group-action_type">
        <label for="input-action-type">
          Jenis Tindakan:
          <em class="text-muted">opsional</em>
        </label>
        <treeselect
          v-model="form.action_type_id"
          :multiple="false"
          placeholder="Pilih Jenis Tindakan"
          :options="actionTypes"
        />
        <small class="text-danger">{{ error.action_type_id }}</small>
      </b-form-group>

      <!-- Input Time Slot -->
      <b-form-group id="input-group-time-slot" label="Slot Waktu:" label-for="input-time-slot">
        <b-form-select
          v-model="form.doctor_time_slot_id"
          :options="timeSlots"
          :disabled="purpose == 'edit' && form.appointment_status_id == 4"
          placeholder="Slot Waktu"
        ></b-form-select>
        <small class="text-danger">{{ error.doctor_time_slot_id }}</small>
      </b-form-group>

      <!-- Input Remarks / Note -->
      <b-form-group id="input-group-notes">
        <label for="input-notes">
          Catatan:
          <em class="text-muted">opsional</em>
        </label>
        <b-form-input id="input-notes" v-model="form.notes" placeholder="Catatan"></b-form-input>
      </b-form-group>

      <!-- Input Status -->
      <b-form-group
        v-if="purpose == 'edit'"
        id="input-group-status"
        label="Status Perjanjian:"
        label-for="input-status"
      >
        <b-form-select
          id="input-status"
          v-model="form.appointment_status_id"
          :disabled="purpose == 'edit' && form.appointment_status_id == 4"
          :options="statuses"
        ></b-form-select>
        <small class="text-danger">{{ error.appointment_status_id }}</small>
      </b-form-group>

      <b-button type="submit" variant="primary">Simpan</b-button>
      <b-button class="ml-2" variant="default" @click="$router.push('/appointment/list/0')">Batal</b-button>
    </b-form>

    <ModalPatient
      :selectedDoctor="form.doctor_id.toString()"
      @chosenPatient="setPatient"
      @submitedPatient="setPatient"
    />
  </div>
</template>

<script>

import Card from '@/view/content/Card.vue'
import ModalPatient from '@/component/general/ModalPatient.vue'
import module from '@/core/modules/CrudModule.js'
import validation from '@/core/modules/ValidationModule.js'
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import { getUser } from '@/core/services/jwt.service.js'

export default {

  props: {
    route: String,
    form: Object,
    purpose: String,
  },

  components: {
    Card,
    ModalPatient,
    Treeselect
  },

  data() {
    return {
      // Display Data
      display: {
        patient_name: ''
      },
      // Error
      error: {
        patient_id: '',
        doctor_id: '',
        registration_date: '',
        time_slot_id: '',
        action_type_id: '',
        appointment_status_id: '',
      },
      // Options
      actionTypes: [],
      timeSlots: [{ text: 'Pilih Slot Waktu', value: '', disabled: true }],
      rooms: [],
      doctors: [],
      statuses: [],
      // Other
      doctorDisabled: this.purpose == 'edit' ? true : false,
      selectedDoctorSchedule: [],
      selectedDoctorScheduleText: "",
      currentUser: getUser()
    }
  },

  methods: {

    setPatient(value) {
      this.form.patient_id = value.id
      this.display.patient_name = value.name
      if (this.currentUser.user_type_id != 1) {
        this.form.doctor_id = ''
        this.getDoctors()
      }
      this.doctorDisabled = false
      this.$bvModal.hide('modal-patient')
    },

    async inputDoctorOnSelect(evt) {
      await evt.id
      this.selectedDoctorSchedule = await module.get(`schedules/get-by-doctor/${evt.id}`)
      this.setScheduleText(this.selectedDoctorSchedule)
    },

    setScheduleText(items) {
      if (items.length != 0) {
        let text = `Jadwal Praktek ${items[0].doctor_name} => `
        items.forEach(function (value, index) {
          text += `${value.day_name} ${value.start_time} - ${value.end_time}`
          if (items.length > 1 && index != items.length - 1) {
            text += ', '
          }
        })
        this.selectedDoctorScheduleText = text
      } else {
        this.selectedDoctorScheduleText = 'Belum Memiliki Jadwal'
      }
    },

    async getRooms() {
      let response = await module.setTreeSelect('rooms')
      // Success
      if (response.state == 'success') {
        // Set Option Select
        this.rooms = response.data
        this.rooms.unshift({ label: 'Pilih Ruangan', id: '', isDisabled: true })
      }
    },

    async getActionTypes() {
      let response = await module.setTreeSelect('payment-categories')
      // Success
      if (response.state == 'success') {
        // Set Option Select
        this.actionTypes = response.data
        this.actionTypes.unshift({ label: 'Pilih Jenis Tindakan', id: '', isDisabled: true })
      }
    },

    async getDoctors() {
      let route
      if (typeof this.form.patient_id !== 'undefined' && this.form.patient_id != '') {
        console.info('ruined')
        route = `doctors/get-by-patient/${this.form.patient_id}`
      } else {
        route = 'doctors'
      }
      let response = await module.setDoctorTreeSelect(route)
      // Success
      if (response.state == 'success') {
        // Set Option Select
        this.doctors = response.data
        this.doctors.unshift({ label: 'Pilih Dokter', id: '', isDisabled: true })
      }
    },

    async getAppointmentStatuses() {
      let response = await module.setSelectOption('appointment-statuses')
      // Success
      if (response.state == 'success') {
        // Set Option Select
        this.statuses = response.data
        this.statuses.unshift({ text: 'Pilih Status', value: '', disabled: true })
      }
    },

    async dateOnChange(value, doctorId) {
      var dateObj = new Date(value)
      var weekday = dateObj.toLocaleString("id-ID", { weekday: "long" })
      let response
      if (this.purpose == 'add') {
        response = await module.setSelectOption('time-slots/get-by-date?date=' + value + "&weekday=" + weekday + '&doctor=' + doctorId)
      } else if (this.purpose == 'edit') {
        response = await module.setSelectOption('time-slots/get-by-date?date=' + value + "&weekday=" + weekday + '&doctor=' + doctorId)
      }
      if (response.state == 'success') {
        this.timeSlots = response.data
        this.timeSlots.unshift({ text: 'Pilih Jadwal', value: '', disabled: true })
      }
    },

    async formOnSubmit() {
      let response = await module.submit(this.form, this.route)
      // Check Response
      if (response.state == 'error') {
        // Validation Error
        this.error = validation.setValidationError(this.error, response.error.errors)
      } else {
        // Success
        this.$root.$emit('UpdateNewAppointment');
        Swal.fire(response.success.title, response.success.message, 'success')
        this.$router.push('/appointment/list/0')
      }
    }

  },

  watch: {
    'form.patient_id': function (newVal) {
      this.getDoctors()
    },
    'form.patient_name': function (newVal, oldVal) {
      this.display.patient_name = newVal
    },
    'form.date': function (newVal, oldVal) {
      if (this.form.doctor_id != '') {
        this.dateOnChange(newVal, this.form.doctor_id)
      }
    },
    'form.doctor_id': async function (newVal, oldVal) {
      if (typeof newVal === 'undefined') this.form.doctor_id = ''
      if (this.form.doctor_id != '') {
        this.selectedDoctorScheduleText = ''
        this.selectedDoctorSchedule = await module.get(`schedules/get-by-doctor/${newVal}`)
        this.setScheduleText(this.selectedDoctorSchedule)
        this.dateOnChange(this.form.date, newVal)
      }
    }
  },

  mounted() {
    // Get Doctors
    this.getDoctors()
    if (this.currentUser.user_type_id == 1) {
      this.form.doctor_id = this.currentUser.id
    }
    // Get Action Types
    this.getActionTypes()
    // Get Rooms
    this.getRooms()
    // Get AppointmentStatuses
    this.getAppointmentStatuses()
  },

}
</script>

<style>
</style>